body, p, h1, h2, h3, h4, h5, h6, span, a, li, ul, ol, input {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body{
  max-width: 100%;
  overflow-x: hidden;
}

.whtfloat{
  position:fixed;
  width:60px;
  height:60px;
  bottom:40px;
  right:40px;
  background-color:#25d366;
  color:#FFF;
  border-radius:50px;
  text-align:center;
font-size:30px;
  box-shadow: 2px 2px 3px #999;
z-index:100;
}
.whtfloat:hover {
  text-decoration: none;
  color: #25D366;
background-color:#fff;
}
.my-float{
  margin-top:16px;
}